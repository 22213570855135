import { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import {
  DarkModeContext,
  BasicButton,
  BasicTextField,
  useDeviceActions,
  useLoadingGif,
  useSnackbar,
  BasicSwitch,
  BasicCard,
} from "@datwyler/shared-components";

const GITEX_DEVICES = [
  "10802056-fe46-4a03-b10e-01b680626e46",
  "193c5341-b586-4b5a-ad5b-b4433729c945",
  "5430bb28-fab3-47fa-b464-e8adea1fa845",
  "323160f7-6ba3-42dc-9b4b-d9e7157fbc2c",
];

const getDefaultValues = (params) => {
  const defaultValues = {};
  params.forEach((param) => {
    let value = param.value;
    if (param.type === "BOOLEAN") value = value === "true";
    defaultValues[param.name] = value;
  });
  return defaultValues;
};

const SetParameter = (props) => {
  const { device, parameters, tenantId } = props;
  const intl = useIntl();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm({
    defaultValues: getDefaultValues(parameters),
  });
  const { colors }: any = useContext(DarkModeContext);

  const {
    setDeviceParams,
    isSetDeviceParamsLoading,
    setDeviceParamsResponseData,
  } = useDeviceActions();

  const colStyle = {
    backgroundColor: colors.deviceDetailTableBg,
    height: "56px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
  };

  const fontStyle = {
    color: colors.dataTableFont,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "20px",
  };

  useEffect(() => {
    if (setDeviceParamsResponseData?.setDeviceParams?.status === "Executed") {
      enqueueSnackbar(intl.formatMessage({ id: "command_sent_with_delay" }), {
        variant: "success",
      });
    }
  }, [setDeviceParamsResponseData]);

  useEffect(() => {
    setIsLoading(isSetDeviceParamsLoading || false);
  }, [isSetDeviceParamsLoading]);

  const handleSave = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      //GITEX Fix
      const deviceId = device?.model?.id;
      const isGitexDevice = GITEX_DEVICES.some((id) => id === deviceId);
      if (isGitexDevice) {
        const params = [];
        const allFormValues = form.getValues();

        Object.keys(allFormValues).forEach((key) => {
          const value = form.getValues(key as any);
          params.push(key + "=" + value);
        });

        setDeviceParams({
          variables: {
            input: {
              tenant: { id: tenantId },
              device: { id: device.id },
              params: params,
            },
          },
        });

        return;
      }

      const modifiedFields: any = Object.keys(form.formState.dirtyFields) || [];
      if (modifiedFields.length > 0) {
        const params = [];
        for (let key of modifiedFields) {
          const value = form.getValues(key);
          params.push(key + "=" + value);
        }

        setDeviceParams({
          variables: {
            input: {
              tenant: { id: tenantId },
              device: { id: device.id },
              params: params,
            },
          },
        });
      }
    }
  };

  const getInput = (data) => {
    switch (data.type) {
      case "BOOLEAN":
        return <BasicSwitch form={form} formItemLabel={data.name} />;
      case "STRING":
        return (
          <BasicTextField
            type={"text"}
            form={form}
            formItemLabel={data.name}
            variant={"outlined"}
            size={"small"}
            InputLabelProps={{
              sx: {
                fontSize: "14px",
                letterSpacing: "-0.02px",
                lineHeight: "20px",
              },
            }}
            InputProps={{
              sx: {
                height: "32px",
                fontSize: "14px",
                letterSpacing: "-0.02px",
                lineHeight: "20px",
              },
            }}
          />
        );
      case "INTEGER":
      case "DOUBLE":
        return (
          <BasicTextField
            type={"number"}
            form={form}
            formItemLabel={data.name}
            variant={"outlined"}
            size={"small"}
            InputLabelProps={{
              sx: {
                fontSize: "14px",
                letterSpacing: "-0.02px",
                lineHeight: "20px",
              },
            }}
            InputProps={{
              sx: {
                height: "32px",
                fontSize: "14px",
                letterSpacing: "-0.02px",
                lineHeight: "20px",
              },
            }}
          />
        );
      default:
        return null;
    }
  };

  const getContent = (data, index) => {
    const isOdd = index % 2 === 0;
    return (
      <Grid item xs={6} key={data.name}>
        <Grid
          container
          sx={{
            borderTop: "1px solid " + colors.setParameterBorder,
            borderRight: isOdd
              ? "1px solid " + colors.setParameterBorder
              : "none",
          }}
        >
          <Grid item xs={9} sx={{ ...colStyle, paddingLeft: "32px" }}>
            <Box sx={{ display: "inline-flex" }}>
              <Typography sx={fontStyle}>
                {data.name ? intl.formatMessage({ id: data.name }) : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sx={colStyle}>
            <Box sx={{ display: "inline-flex" }}>{getInput(data)}</Box>
          </Grid>
          <Grid item xs={1} sx={colStyle}>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{ ...fontStyle, color: colors.dataTableHeaderFont }}
              >
                {data.unit}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicCard
      sx={{
        borderRadius: "8px",
        boxShadow: "none",
        marginTop: "24px",
        padding: 0,
      }}
    >
      <LoadingGif />
      <Box
        sx={{ display: "flex", justifyContent: "end", padding: "12px 16px" }}
      >
        <BasicButton onClick={handleSave} variant={"contained"}>
          {intl.formatMessage({ id: "save" })}
        </BasicButton>
      </Box>
      <Box sx={{ marginTop: "12px" }}>
        <Grid container>
          {parameters.map((parameter, index) => {
            return getContent(parameter, index);
          })}
          {parameters?.length % 2 !== 0 &&
            parameters?.length > 1 &&
            getContent({}, parameters.length)}
        </Grid>
      </Box>
    </BasicCard>
  );
};

export default SetParameter;
